import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import {
  Login,
  Home,
  Menu,
  Signup,
  Footer,
  Listing,
  StepperForm,
  AdminLogin,
  AdminRestaurants,
} from "./components";
import UserProfile from "./components/userprofile/UserProfile";
import QrComponents from "./components/qrComponents/QrComponents";
import Hours from "./components/hours/Hours";

export default function Router() {
  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <Routes>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="signup/details" element={<StepperForm />} />
        <Route path="menu" element={<Menu />} />
        <Route path="qrcode" element={<QrComponents />} />
        <Route path="view-listing/:userId" element={<Listing />} />
        <Route path="userinfo" element={<UserProfile />} />
        <Route path="hours" element={<Hours />} />

        {/* Admin Routes */}
        <Route path="admin">
          <Route path="login" element={<AdminLogin />} />
          <Route path="restaurants" element={<AdminRestaurants />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}
