import React, { useState } from "react";
import { StepperForm } from "../signup/StepperForm";


export default function UserProfile() {
  return (
    <>
      <StepperForm />
    </>
  );
}
