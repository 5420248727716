import React from "react";

import {
  Container,
  Grid,
  Checkbox,
  Typography,
  FormControlLabel,
  Button,
} from "@mui/material";
import Header from "../header/Header";
import style from "./style.module.css";

const Hours = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <form action="">
          <Grid container spacing={2} sx={{ textAlign: "center" }}>
            <Grid item sm={5} xs={6} sx={{ mb: 3, fontWeight: "bold" }}>
              <Typography variant="p">
                <b> Opening Time</b>
              </Typography>
            </Grid>
            <Grid item sm={4} xs={6} sx={{ mb: 3, fontWeight: "bold" }}>
              <Typography variant="p">
                <b>Closing Time</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={1} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <label>Monday</label>
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={12}
              xs={12}
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Closed"
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Add Open & Closing Time"
              />
            </Grid>

            <Grid
              item
              lg={1}
              sm={12}
              xs={12}
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              <label>Tuesday</label>
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={12}
              xs={12}
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Closed"
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Add Open & Closing Time"
              />
            </Grid>

            <Grid item lg={1} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <label>Wednesday</label>
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={3} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Closed"
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Add Open & Closing Time"
              />
            </Grid>

            <Grid item lg={1} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <label>Thursday</label>
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={3} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Closed"
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Add Open & Closing Time"
              />
            </Grid>

            <Grid item lg={1} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <label>Friday</label>
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={3} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Closed"
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Add Open & Closing Time"
              />
            </Grid>

            <Grid item lg={1} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <label>Saturday</label>
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={3} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Closed"
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Add Open & Closing Time"
              />
            </Grid>

            <Grid item lg={1} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <label>Sunday</label>
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={4} sm={12} xs={12} sx={{ textAlign: "center" }}>
              <input type="time" required className={style.time_input} />
            </Grid>
            <Grid item lg={3} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Closed"
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Add Open & Closing Time"
              />
            </Grid>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "20px 0 50px 0",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "200px", marginTop: "20px", marginRight: "50px" }}
              className={style.hoursButton}
            >
              Save
            </Button>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default Hours;
