import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBY4jc9w5pPUU5MU0TQq5UbLJwnBdzMPK8",
  authDomain: "menuboss-3e415.firebaseapp.com",
  projectId: "menuboss-3e415",
  storageBucket: "menuboss-3e415.appspot.com",
  messagingSenderId: "764346840168",
  appId: "1:764346840168:web:103df5cdb203597c7db7dd",
  measurementId: "G-VVSCWKHQY0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
