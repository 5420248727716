import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  TextField,
} from "@mui/material";
import Header from "../header/Header";
import { Check, ShoppingBasketOutlined } from "@mui/icons-material";
import style from "./style.module.css";

export function Home() {
  return (
    <>
      <Header />
      <section className={style.image_bg_sec}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100vh"
              >
                <Box>
                  <Typography variant="h3">
                    <b>Good food choices are good investments.</b>
                  </Typography>
                  <br />
                  <br />
                  <Typography>
                    <b>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Sapiente soluta sed necessitatibus vitae dolor accusantium
                      accusamus itaque earum deserunt obcaecati atque, corrupti,
                      tempora rerum reprehenderit dolorem neque repudiandae
                      dignissimos vel?
                    </b>
                  </Typography>
                  <br />
                  <br />
                  <Button variant="contained" color="primary" sx={{ p: 2 }}>
                    <b>Order Now</b>
                    <ShoppingBasketOutlined sx={{ ml: 1 }} />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
      <br />
      <br />
      <section className={style.bg_color}>
        <Container maxWidth="lg" sx={{ pt: 7, pb: 7, mb: 15, mt: -6 }}>
          <Grid container spacing={2} textAlign="center" color="white">
            <Grid item md={3}>
              <Typography variant="h3">
                <b>1287+</b>
              </Typography>
              <Typography variant="h6">
                <b>SAVINGS</b>
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h3">
                <b>5786+</b>
              </Typography>
              <Typography variant="h6">
                <b>PHOTOS</b>
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h3">
                <b>1440+</b>
              </Typography>
              <Typography variant="h6">
                <b>ROCKETS</b>
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h3">
                <b>7110+</b>
              </Typography>
              <Typography variant="h6">
                <b>GLOBES</b>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <br />
      <br />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <img
              src="/images/home1.png"
              alt="Image"
              className={style.borderRadius_img}
            />
          </Grid>
          <Grid item md={6}>
            <Box sx={{ m: 3 }}>
              <Typography variant="h3" color="#43121d">
                <b> We pride ourselves on making real</b>
              </Typography>
              <br />
              <br />
              <Typography color="#555">
                <b>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Corrupti culpa consequuntur ducimus! Reprehenderit voluptas
                  ratione vero deleniti fugit velit ad iusto similique ea.
                  Quibusdam maxime dolorem ducimus recusandae neque dignissimos?
                </b>
              </Typography>
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                sx={{ pt: 1, pb: 1, pl: 4, pr: 4 }}
              >
                <b>Learn More</b>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box sx={{ m: 3 }}>
              <Typography variant="h3" color="#43121d">
                <b>We make everything by hand</b>
              </Typography>
              <br />
              <br />
              <Typography>
                <b>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Corrupti culpa consequuntur ducimus! Reprehenderit voluptas
                  ratione vero deleniti fugit velit ad iusto similique ea.
                  Quibusdam maxime dolorem ducimus recusandae neque dignissimos?
                </b>
              </Typography>
              <Typography
                sx={{ mt: 5 }}
                display="flex"
                alignItems="center"
                color="#555"
              >
                <Check color="primary" />
                <b style={{ marginLeft: "10px" }}>
                  Etiam sed dolor ac diam volutpat.
                </b>
              </Typography>
              <Typography
                sx={{ mt: 2 }}
                display="flex"
                alignItems="center"
                color="#555"
              >
                <Check color="primary" />
                <b style={{ marginLeft: "10px" }}>
                  Etiam sed dolor ac diam volutpat.
                </b>
              </Typography>
              <Typography
                sx={{ mt: 2 }}
                display="flex"
                alignItems="center"
                color="#555"
              >
                <Check color="primary" />
                <b style={{ marginLeft: "10px" }}>
                  Etiam sed dolor ac diam volutpat.
                </b>
              </Typography>
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                sx={{ pt: 1, pb: 1, pl: 4, pr: 4 }}
              >
                <b>Learn More</b>
              </Button>
            </Box>
          </Grid>
          <Grid item md={6}>
            <img
              src="/images/home3.png"
              alt="Image"
              sx={{ borderRadius: 10 }}
            />
          </Grid>
        </Grid>
      </Container>

      <section className={style.image_bg_sec2}>
        <Container maxWidth="md">
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h3" sx={{ pt: 15, pb: 2 }} color="primary">
              <b>
                When stomach is full it makes no difference whether he is rich
                or poor.
              </b>
            </Typography>
            <Typography sx={{ pb: 20 }} color="#555">
              <b>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                et purus a odio finibus bibendum in sit amet leo. Mauris feugiat
                erat tellus.
              </b>
            </Typography>
          </Box>
        </Container>
      </section>

      <Container maxWidth="md">
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h3" sx={{ pt: 15, pb: 2 }} color="#43121d">
            <b>Explore Our Foods</b>
          </Typography>
          <Typography sx={{ pb: 10 }} color="#555">
            <b>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et
              purus a odio finibus bibendum in sit amet leo. Mauris feugiat erat
              tellus. Far far away, behind the word mountains, far from the
              countries Vokalia and Consonantia, there live the blind texts.
              Separated they live in Bookmarksgrove. tellus.
            </b>
          </Typography>
        </Box>
      </Container>

      <Container>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Card>
              <CardMedia
                component="img"
                height="250"
                image="/images/home01.jpg"
                alt="Image"
              />
              <CardContent>
                <Typography variant="h6">
                  <b>Rainbow Vegetable Sandwich</b>
                </Typography>
                <Typography variant="h6" color="secondary">
                  <b>Time: 15 - 20 Minutes | Serves: 1</b>
                </Typography>
                <Box display="flex" sx={{ mt: 3 }}>
                  <Typography variant="h6" color="primary">
                    <b>$10.50</b>
                  </Typography>
                  <Typography variant="h6" color="secondary" sx={{ ml: 2 }}>
                    <b>
                      <del>$10.50</del>
                    </b>
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button variant="contained" sx={{ mb: 2 }}>
                  <b>View More</b>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardMedia
                component="img"
                height="250"
                image="/images/home02.jpg"
                alt="Image"
              />
              <CardContent>
                <Typography variant="h6">
                  <b>Vegetarian Burger</b>
                </Typography>
                <Typography variant="h6" color="secondary">
                  <b>Time: 15 - 20 Minutes | Serves: 1</b>
                </Typography>
                <Box display="flex" sx={{ mt: 3 }}>
                  <Typography variant="h6" color="primary">
                    <b>$10.50</b>
                  </Typography>
                  <Typography variant="h6" color="secondary" sx={{ ml: 2 }}>
                    <b>
                      <del>$10.50</del>
                    </b>
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" sx={{ mb: 2 }}>
                  <b>View More</b>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardMedia
                component="img"
                height="250"
                image="/images/home03.jpg"
                alt="Image"
              />
              <CardContent>
                <Typography variant="h6">
                  <b>Raspberry Stuffed French Toast</b>
                </Typography>
                <Typography variant="h6" color="secondary">
                  <b>Time: 15 - 20 Minutes | Serves: 1</b>
                </Typography>
                <Box display="flex" sx={{ mt: 3 }}>
                  <Typography variant="h6" color="primary">
                    <b>$10.50</b>
                  </Typography>
                  <Typography variant="h6" color="secondary" sx={{ ml: 2 }}>
                    <b>
                      <del>$10.50</del>
                    </b>
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" sx={{ mb: 2 }}>
                  <b>View More</b>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 10, mb: 10 }}>
        <Typography variant="h4" textAlign="center" sx={{ mt: 15, mb: 10 }}>
          <b>Frequently Asked Questions</b>
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box sx={{ m: 3 }}>
              <Typography variant="h5">
                <b>~ Is Foodera Bread really baked fresh</b>
              </Typography>
              <Typography sx={{ mt: 1 }}>
                <b>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  Separated they live in Bookmarksgrove right at the coast of
                  the Semantics, a large language.
                </b>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ m: 3 }}>
              <Typography variant="h5">
                <b>~ Do you bake breads containing</b>
              </Typography>
              <Typography sx={{ mt: 1 }}>
                <b>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  Separated they live in Bookmarksgrove right at the coast of
                  the Semantics, a large language.
                </b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box sx={{ m: 3 }}>
              <Typography variant="h5" color="#43121d">
                <b>~ Can I order your products online?</b>
              </Typography>
              <Typography sx={{ mt: 1 }} textAlign="justify">
                <b>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  Separated they live in Bookmarksgrove right at the coast of
                  the Semantics, a large language.
                </b>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ m: 3 }}>
              <Typography variant="h5" color="#43121d">
                <b>~ When are you opening a shop near me?</b>
              </Typography>
              <Typography sx={{ mt: 1 }} textAlign="justify">
                <b>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  Separated they live in Bookmarksgrove right at the coast of
                  the Semantics, a large language.
                </b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <section className={style.image_bg_sec3}>
        <Container sx={{ pt: 10, pb: 10 }}>
          <Box textAlign="center">
            <Typography variant="h4" sx={{ mb: 5 }} color="white">
              <b>Baked fresh daily by bakers with passion.</b>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}
            >
              <b>Learn More</b>
            </Button>
          </Box>
        </Container>
      </section>

      <Container maxWidth="md">
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h3" sx={{ pt: 15, pb: 2 }} color="#43121d">
            <b>Hurry up! Subscribe our newsletter and get 25% Off</b>
          </Typography>
          <Typography sx={{ pb: 5 }} color="#555">
            <b>Limited time offer for this month. No credit card required.</b>
          </Typography>

          <Container maxWidth="xs">
            <Box display="flex" alignItems="center" justifyContent="center">
              <TextField
                label="Email"
                placeholder="Enter Your Email"
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 2, p: 2, pr: 5, pl: 5 }}
              >
                <b>Subscribe</b>
              </Button>
            </Box>
          </Container>
        </Box>
      </Container>
    </>
  );
}
