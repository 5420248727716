import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { Container } from "@mui/material";
import { db } from "../../firebase";
import AdminHeader from "../header/AdminHeader";
import style from "./style.module.css";

const collRef = collection(db, "restaurants");

export function AdminRestaurants() {
  const [arr, setArr] = useState([]);

  const getFirebaseData = () => {
    getDocs(collRef)
      .then((res) => {
        var temp = [];
        res.docs.forEach((e) => {
          temp.push({ ...e.data().data });
        });
        setArr(temp);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getFirebaseData();
  }, []);

  console.log(arr);

  return (
    <>
      <AdminHeader />
      <Container maxWidth="lg">
        <table className={style.admin_table}>
          <thead>
            <th>Restaurant Id</th>
            <th>Restaurant Name</th>
            <th>Restaurant Type</th>
            <th>Phone</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
          </thead>
          <br />
          <tbody>
            {arr &&
              arr.map((e) => {
                return (
                  <tr key={e.userId}>
                    <td>{e.userId}</td>
                    <td>{e.userName}</td>
                    <td>{e.type}</td>
                    <td>{e.phone}</td>
                    <td>{e.city}</td>
                    <td>{e.inputState}</td>
                    <td>{e.inputCountry}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Container>
    </>
  );
}
