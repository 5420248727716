import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import ContextProvider from "./context";
import Router from "./router";
import { theme } from "./theme";
import "./style.css";

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ContextProvider>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </ContextProvider>
  </BrowserRouter>
);
