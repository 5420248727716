import { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

const Context = createContext({});

export default function ContextProvider({ children }) {
  const [checkLogin, setCheckLogin] = useState(false);
  const [userId, setUserId] = useState("");
  const [activeStep, setActiveStep] = useState(1);

  class Updater {
    constructor() {
      this.updateStep = () => setActiveStep(activeStep + 1);
    }
  }

  const checkAuth = () => {
    onAuthStateChanged(auth, (res) => {
      if (res) {
        setCheckLogin(true);
        setUserId(res.uid);
        console.log(res.reloadUserInfo);
      }
    });
  };

  useEffect(() => {
    checkAuth();
  }, [userId]);

  return (
    <Context.Provider value={{ checkLogin, userId, activeStep, Updater }}>
      {children}
    </Context.Provider>
  );
}

export function UpdateContext() {
  const { Updater } = useContext(Context);
  const Update = new Updater();

  return Update;
}

export function ReadContext() {
  const { checkLogin } = useContext(Context);
  const { userId } = useContext(Context);
  const { activeStep } = useContext(Context);

  const value = {
    activeStep,
    checkLogin,
    userId,
  };

  return value;
}
