import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, TextField, Button, Alert } from "@mui/material";
import AdminHeader from "../header/AdminHeader";
import style from "./style.module.css";

export function AdminLogin() {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();

    if (user == "test@uxdlab.us" && pass == "test@1234")
      navigate("/admin/restaurants");
    else setShowAlert(true);
  };

  return (
    <>
      <AdminHeader />
      <section>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <div className={style.image_sec}></div>
          </Grid>
          <Grid item md={6}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              className={style.input_box}
            >
              <Box>
                <Typography variant="h3">
                  <b>LOGIN</b>
                </Typography>
                <br />
                <br />
                <form className={style.login_form} onSubmit={handleLogin}>
                  {showAlert ? (
                    <Alert severity="error">User Not Found</Alert>
                  ) : (
                    <></>
                  )}
                  <br />
                  <TextField
                    label="E-Mail"
                    placeholder="Enter Your Email"
                    type={"email"}
                    sx={{ m: 1 }}
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    fullWidth
                    autoFocus
                    required
                  />
                  <TextField
                    label="Password"
                    placeholder="Enter Your Password"
                    type={"password"}
                    sx={{ m: 1 }}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    fullWidth
                    required
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ m: 1 }}
                  >
                    Login
                  </Button>
                </form>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
