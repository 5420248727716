import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  Container,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { GetData } from "../../dbFunctions";
import AdminHeader from "../header/AdminHeader";

export function Listing() {
  const { userId } = useParams();
  const [arr, setArr] = useState([]);

  useEffect(() => {
    GetData("products")
      .then((res) => setArr(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <AdminHeader />
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Grid container spacing={2}>
          {arr &&
            arr
              .filter((el) => el.userId == userId)
              .map((e, index) => {
                return (
                  <Grid item md={4} key={index}>
                    <Card>
                      <CardMedia
                        component="img"
                        height="250"
                        image={e.img}
                        alt="Burger"
                      />
                      <CardContent sx={{ textAlign: "center" }}>
                        <Typography variant="h5" color="primary">
                          <b>{e.name}</b>
                        </Typography>
                        <Typography variant="h6" color="secondary">
                          <b>{e.desc}</b>
                        </Typography>
                        <br />
                        <Box display="flex">
                          <Typography
                            variant="h6"
                            color="primary"
                            sx={{ ml: 2 }}
                          >
                            <b>$ {e.price}</b>
                          </Typography>
                          <Typography
                            variant="h6"
                            color="secondary"
                            sx={{ ml: 4 }}
                          >
                            <b>
                              <del>
                                {[parseInt(e.price), 5].reduce((a, b) => a + b)}
                                .50
                              </del>
                            </b>
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
        </Grid>
      </Container>
    </>
  );
}
