import { Container, Button } from "@mui/material";
import style from "./style.module.css";

export default function AdminHeader() {
  return (
    <header className={style.header_sec}>
      <Container maxWidth="lg">
        <Button variant="text" color="primary" sx={{ fontSize: 20 }}>
          <b>Menu Boss</b>
        </Button>
      </Container>
    </header>
  );
}
