import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Button,
  Grid,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import { ReadContext } from "../../context";
import style from "./style.module.css";

export default function Header() {
  const [userName, setUserName] = useState("");
  const [useremail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const { checkLogin } = ReadContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const checkAuth = () => {
    onAuthStateChanged(auth, (res) => {
      if (res) {
        setUserName(res.displayName);
        setUserEmail(res.email);
        console.log("Response 49 Line : ", res.displayName);
      }
    });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <>
      <header className={style.header_sec}>
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={() => navigate("/")}
            sx={{ fontSize: 20 }}
            className={style.button}
          >
            {userName ? <b>{userName}</b> : <b>Menu Boss</b>}
          </Button>
          {checkLogin ? (
            <Grid sx={{ marginTop: "10px" }}>
              {/* <b>Logout</b> */}
              <img
                src="/images/userdata.png"
                style={{
                  width: "35px",
                  cursor: "pointer",
                }}
                className={style.headerImg}
                // onClick={handleLogout}
                onClick={handleClick}
              />
            </Grid>
          ) : (
            <Box
              sx={{ textAlign: "center", display: "flex", marginTop: "20px" }}
            >
              <Link to="/login">
                <Box sx={{ width: "100px" }}>Restaurant Sign In</Box>
              </Link>
              <Link to="/signup">
                <Box sx={{ width: "100px" }}>Restaurant Sign Up</Box>
              </Link>
            </Box>
          )}
        </Container>
        <Link to="/userinfo">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              right: 10,
              top: 15,
            }}
          ></div>
        </Link>
      </header>

      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            // onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          ></IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            display: "flex",
            justifyContent: "center",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box className={style.menuList}>
          <MenuItem className={style.title}>
            <b>{userName}</b>
          </MenuItem>
          <MenuItem sx={{ marginTop: "-10px" }} className={style.title}>
            <b>{useremail}</b>
          </MenuItem>
          <Divider />
          <MenuItem>
            <Link to="/userinfo">Edit Profile</Link>
          </MenuItem>

          <MenuItem>
            <Link to="/hours">Edit Hours</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/menu">Edit Menu</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/qrcode">QR Code</Link>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Link to="/">Sign Out</Link>
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
}
