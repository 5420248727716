import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, TextField, Box, Typography, Button, Alert } from "@mui/material";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../../firebase";
import style from "./style.module.css";
import Header from "../header/Header";

export function Signup() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleSignUp = (e) => {
    e.preventDefault();

    if (confirmPass !== pass) {
      setShowAlert(true);
      return;
    }

    sessionStorage.setItem("userName", name);

    createUserWithEmailAndPassword(auth, user, pass)
      .then(() => {
        updateProfile(auth.currentUser, {
          displayName: name,
        })
          .then(() => {
            console.log("Signup Completed");
            navigate("./details");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Header />
      <section>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <div className={style.image_sec}></div>
          </Grid>
          <Grid item md={6}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              className={style.input_box}
            >
              <div>
                <Typography variant="h3">
                  <b>Sign Up</b>
                </Typography>
                <br />
                <br />
                <form className={style.signup_form} onSubmit={handleSignUp}>
                  {showAlert ? (
                    <Alert severity="error">Passwords Does not Match</Alert>
                  ) : (
                    <></>
                  )}
                  <br />
                  <TextField
                    label="Restaurant Name"
                    placeholder="Enter Your Restaurant's Name"
                    type={"text"}
                    sx={{ mb: 3 }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                    required
                  />
                  <TextField
                    label="Email"
                    placeholder="Enter Your Email"
                    type={"email"}
                    sx={{ mb: 2 }}
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Password"
                    placeholder="Enter Your Password"
                    type={"password"}
                    sx={{ mb: 2 }}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Confirm Password"
                    placeholder="Enter Your Password"
                    type={"password"}
                    sx={{ mb: 2 }}
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    fullWidth
                    required
                  />
                  <Button variant="contained" type="submit" fullWidth>
                    Signup
                  </Button>
                  <br />
                  <br />
                  <Link to="/login">Login</Link>
                </form>
              </div>
            </Box>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
