import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Quicksand"],
  },

  palette: {
    primary: {
      main: "#a82d49",
    },
    secondary: {
      main: "#a9a9a9",
    },
  },
});
