import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { QrCodeScanner } from "@mui/icons-material";
import QRCode from "react-qr-code";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import randomatic from "randomatic";
import { SendData } from "../../dbFunctions";
import { storage, db } from "../../firebase";
import { ReadContext } from "../../context";
import Header from "../header/Header";
import style from "./style.module.css";
import qrcode from "qrcode";

export function Menu() {
  const { userId } = ReadContext();

  console.log(userId);

  const [arr, setArr] = useState([]);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("/images/placeholder.png");
  const [imgUrl, setImgUrl] = useState(null);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState();
  const [disable, setDisable] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [url, setUrl] = useState("");
  const [qr, setQr] = useState("");

  useEffect(() => {
    GenerateQRCode();
  }, [dialog, url]);

  const showImg = (e) => {
    setImgUrl(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const collRef = collection(db, "products");

  const getFirebaseData = () => {
    getDocs(collRef)
      .then((res) => {
        var temp = [];
        res.docs.forEach((e) => {
          temp.push({ ...e.data().data });
        });
        setArr(temp);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const burgerRef = ref(storage, `images/${randomatic("Aa0", 30)}`);

    uploadBytes(burgerRef, imgUrl)
      .then((res) => {
        getDownloadURL(res.ref)
          .then((img) => {
            const val = {
              userId,
              name,
              img,
              desc,
              price,
            };
            SendData(val, "products")
              .then(() => setDisable(true))
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    setOpen(!open);
  };

  const GenerateQRCode = () => {
    setUrl(`https://foodmenu-website.pages.dev/view-listing/${userId}`);
    qrcode.toDataURL(
      url,
      {
        width: 260,
        margin: 2,
        color: {
          dark: "#000000",
          light: "#FFFFFF",
        },
      },
      (err, url) => {
        if (err) return console.error(err);

        setQr(url);
      }
    );
  };

  useEffect(() => {
    getFirebaseData();
  }, []);

  return (
    <>
      <Header />
      {/* <Container maxWidth="lg" sx={{ p: 2, textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ p: 2 }}
          onClick={() => {
            setDialog(!dialog);
          }}
        >
          <b> View QR Code </b>
          <QrCodeScanner sx={{ ml: 2 }} />
        </Button>
      </Container> */}

      <Container maxWidth="lg" sx={{ p: 2, textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ p: 2 }}
          onClick={() => setOpen(!open)}
        >
          <b> Add New Product </b>
        </Button>
      </Container>

      <Dialog
        open={dialog}
        onClose={() => setDialog(!dialog)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
          {"Scan This QR-Code"}
        </DialogTitle>
        <DialogContent>
          {qr && (
            <>
              <img src={qr} draggable="false" />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <a href={qr} download="qrcode.png">
            <Button variant="contained" color="primary">
              Download
            </Button>
          </a>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDialog(!dialog);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Container maxWidth="xl" sx={{ mt: 5 }}>
        <Grid container spacing={5}>
         
            <Box>
              <Modal open={open} onClose={() => setOpen(!open)}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100vh"
                >
                  <div className={style.modal_box}>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <img
                            src={image}
                            alt="img"
                            className={style.uploadImg}
                          />
                          <Box
                            sx={{
                              backgroundColor: "lightgray",
                              p: 2,
                              m: 2,
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              onChange={showImg}
                              className={style.img_upload}
                              required
                            />
                          </Box>
                        </Grid>
                        <Grid item md={6}>
                          <Box
                            sx={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box sx={{ p: 2 }}>
                              <TextField
                                label="Product Name"
                                placeholder="Enter Your Product's Name"
                                type={"text"}
                                sx={{ mb: 3, mt: 3 }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                                required
                              />
                              <TextField
                                label="Product Description"
                                placeholder="Enter Your Product's Description"
                                type={"text"}
                                rows={3}
                                sx={{ mb: 3 }}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                                multiline
                                fullWidth
                                required
                              />
                              <TextField
                                label="Product Price"
                                placeholder="Enter Your Product's Price"
                                type={"number"}
                                sx={{ mb: 3, mt: 3 }}
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                fullWidth
                                required
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ mr: 2, width: 1 / 3 }}
                                type="submit"
                              >
                                Submit
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{ width: 1 / 3 }}
                                onClick={() => setOpen(!open)}
                              >
                                <b>Close</b>
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Box>
              </Modal>
            </Box>

    
          

          {arr &&
            arr
              .filter((e) => e.userId === userId)
              .map((e, index) => {
                return (
                  <Grid item lg={3} md={4} xs={12} key={index}>
                    <Card>
                      <CardMedia
                        component="img"
                        width="100%"
                        height="250"
                        image={e.img}
                        alt="Burger"
                      />
                      <CardContent>
                        <Typography variant="h5" color="primary">
                          <b>{e.name}</b>
                        </Typography>
                        <Typography variant="h6" color="secondary">
                          <b>{e.desc}</b>
                        </Typography>
                        <br />
                        <Box display="flex">
                          <Typography variant="h6" color="primary">
                            <b>$ {e.price}</b>
                          </Typography>
                          <Typography
                            variant="h6"
                            color="secondary"
                            sx={{ ml: 2 }}
                          >
                            <b>
                              <del>
                                ${" "}
                                {[parseInt(e.price), 5].reduce((a, b) => a + b)}
                                .50
                              </del>
                            </b>
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
        </Grid>
        <Snackbar
          open={disable}
          autoHideDuration={5000}
          onClose={() => setDisable(!disable)}
        >
          <Alert
            severity="success"
            onClose={() => setDisable(!disable)}
            sx={{ width: "100%" }}
          >
            Data Added To Database....
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}
