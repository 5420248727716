import { Step, StepLabel, Stepper, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SendData } from "../../dbFunctions";
import { ReadContext } from "../../context";
import { StepOne, StepTwo, StepThree } from "./Steps";
import Header from "../header/Header";

export function StepperForm() {
  const navigate = useNavigate();
  const { activeStep } = ReadContext();
  const { userId } = ReadContext();

  const changeForm = () => {
    switch (activeStep) {
      case 1:
        return <StepOne />;

      case 2:
        return <StepTwo />;

      case 3:
        return <StepThree />;
    }
  };

  if (activeStep > 3) {
    const userName = sessionStorage.getItem("userName");
    const step1 = JSON.parse(sessionStorage.getItem("step1"));
    const step2 = JSON.parse(sessionStorage.getItem("step2"));
    const step3 = JSON.parse(sessionStorage.getItem("step3"));

    const out = { userId, userName, ...step1, ...step2, ...step3 };

    console.log("Out Data Line No 34 : ", out);

    SendData(out, "restaurants")
      .then(() => navigate("/menu"))
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Header />
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>Restaurant Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Establishment Type</StepLabel>
          </Step>
          <Step>
            <StepLabel>Operational Hours</StepLabel>
          </Step>
        </Stepper>
      </Container>
      {changeForm()}
    </>
  );
}
