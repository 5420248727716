import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Radio,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Grid,
  Button,
} from "@mui/material";
import { UpdateContext, ReadContext } from "../../context";
import country from "../../data/country.json";
import style from "./style.module.css";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";

export function StepOne() {
  const { updateStep } = UpdateContext();
  const { userId } = ReadContext();

  const [restaurantAddress, setRestaurantAddress] = useState("");
  const [pinCode, setPinCode] = useState();
  const [inputCountry, setInputCountry] = useState("");
  const [inputState, setInputState] = useState("");
  const [city, setCity] = useState("");

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    const val = {
      restaurantAddress,
      pinCode,
      inputCountry,
      inputState,
      city,
      phone,
      name,
    };

    sessionStorage.setItem("step1", JSON.stringify(val));
    updateStep();
  };

  const getFirebaseData = async () => {
    const collRef = collection(db, "restaurants");
    const data = getDocs(collRef).then((resp) => {
      resp.forEach((data) => {
        if (data.data().data.userId === userId) {
          console.log("response is ", data.data());
        }
      });
    });
  };

  useEffect(() => {
    getFirebaseData();
  }, []);

  return (
    <>
      <Container maxWidth="md">
        <form onSubmit={handleSubmit}>
          <Box sx={{ border: 1, borderRadius: "5px", p: 2 }}>
            <Typography variant="h5">
              <b>Restaurant Details</b>
            </Typography>
            <Typography>Name and address</Typography>
            <br />
            <br />
            <TextField
              label="Restaurant Address"
              placeholder="Enter Your Restaurant Address"
              rows={3}
              type={"text"}
              sx={{ mb: 3 }}
              value={restaurantAddress}
              onChange={(e) => setRestaurantAddress(e.target.value)}
              multiline
              autoFocus
              fullWidth
              required
            />
            <Autocomplete
              options={country}
              sx={{ mb: 3 }}
              inputValue={inputCountry}
              onInputChange={(e, newInputValue) => {
                setInputCountry(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Country" required />
              )}
            />
            <TextField
              label="PinCode"
              placeholder="Enter Your PinCode"
              type={"number"}
              sx={{ mb: 3 }}
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="State/Province"
              placeholder="Enter Your State/Province"
              type={"text"}
              sx={{ mb: 3 }}
              value={inputState}
              onChange={(e) => setInputState(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="City"
              placeholder="Enter Your City"
              type={"text"}
              sx={{ mb: 3 }}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
              required
            />
          </Box>
          <br />
          <Box sx={{ border: 1, borderRadius: "5px", p: 2 }}>
            <Typography variant="h5">
              <b>Restaurant Owner Details</b>
            </Typography>
            <Typography>
              This will be used for related communications
            </Typography>
            <br />
            <br />
            <TextField
              label="Restaurant Owner Mobile Number"
              placeholder="Enter Restaurant Owner Mobile Number"
              type={"number"}
              sx={{ mb: 3 }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
              required
            />
            <TextField
              label="Restaurant Owner Name"
              placeholder="Enter Your Name"
              type={"text"}
              sx={{ mb: 3 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
            />
          </Box>

          <Box sx={{ textAlign: "right", mt: 5, mb: 5 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ width: "50%" }}
            >
              Next
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}

export function StepTwo() {
  const { updateStep } = UpdateContext();

  const [type, setType] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const val = {
      type,
    };

    sessionStorage.setItem("step2", JSON.stringify(val));
    updateStep();
  };

  return (
    <>
      <Container maxWidth="md">
        <form onSubmit={handleSubmit}>
          <Box sx={{ border: 1, borderRadius: "5px", p: 2 }}>
            <Typography variant="h5">
              <b>Establishment Type</b>
            </Typography>
            <Typography>
              Select most relevant category for your restaurant type
            </Typography>
            <br />
            <br />
            <RadioGroup
              name="btnGroup"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <FormControlLabel
                value={"Both delivery and dine in"}
                label={"Both delivery and dine in"}
                control={<Radio />}
              />
              <Typography sx={{ ml: 5, mb: 2 }}></Typography>
              <FormControlLabel
                value={"Dine in only"}
                label={"Dine in only"}
                control={<Radio />}
              />
              <Typography sx={{ ml: 5, mb: 2 }}></Typography>
              <FormControlLabel
                value={"Delivery only"}
                label={"Delivery only"}
                control={<Radio />}
              />
              <Typography sx={{ ml: 5, mb: 2 }}></Typography>
            </RadioGroup>
          </Box>

          <Box sx={{ textAlign: "right", mt: 5, mb: 5 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ width: "50%" }}
            >
              Next
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}

export function StepThree() {
  const { updateStep } = UpdateContext();

  const [openTime, setOpenTime] = useState();
  const [closeTime, setCloseTime] = useState();

  const [mon, setMon] = useState(false);
  const [tue, setTue] = useState(false);
  const [wed, setWed] = useState(false);
  const [thu, setThu] = useState(false);
  const [fri, setFri] = useState(false);
  const [sat, setSat] = useState(false);
  const [sun, setSun] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const val = {
      openTime,
      closeTime,
      Monday: mon,
      Tuesday: tue,
      Wednesday: wed,
      Thursday: thu,
      Friday: fri,
      Saturday: sat,
      Sunday: sun,
    };

    sessionStorage.setItem("step3", JSON.stringify(val));
    updateStep();
  };

  return (
    <>
      <Container maxWidth="md">
        <form onSubmit={handleSubmit}>
          <Box sx={{ border: 1, borderRadius: "5px", p: 2 }}>
            <Typography variant="h5">
              <b>Restaurant Operational Hours</b>
            </Typography>
            <Typography>Mark restaurant opening and closing hours.</Typography>
            <br />
            <br />
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <input
                  type="time"
                  onChange={(e) => setOpenTime(e.target.value)}
                  className={style.time_input}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <input
                  type="time"
                  onChange={(e) => setCloseTime(e.target.value)}
                  className={style.time_input}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ m: 2 }}>
              <Grid item sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mon}
                      onChange={(e) => setMon(e.target.checked)}
                    />
                  }
                  label="Monday"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tue}
                      onChange={(e) => setTue(e.target.checked)}
                    />
                  }
                  label="Tuesday"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wed}
                      onChange={(e) => setWed(e.target.checked)}
                    />
                  }
                  label="Wednesday"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={thu}
                      onChange={(e) => setThu(e.target.checked)}
                    />
                  }
                  label="Thursday"
                />
              </Grid>
              <Grid item sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fri}
                      onChange={(e) => setFri(e.target.checked)}
                    />
                  }
                  label="Friday"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sat}
                      onChange={(e) => setSat(e.target.checked)}
                    />
                  }
                  label="Saturday"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sun}
                      onChange={(e) => setSun(e.target.checked)}
                    />
                  }
                  label="Sunday"
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ textAlign: "right", mt: 5, mb: 5 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ width: "50%" }}
            >
              Next
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}
