import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export async function SendData(data, collectionName) {
  await addDoc(collection(db, collectionName), {
    data,
  });
}

export async function GetData(collectionName) {
  const collRef = collection(db, collectionName);

  const out = getDocs(collRef)
    .then((res) => {
      var temp = [];
      res.docs.forEach((e) => {
        temp.push({ ...e.data().data });
      });
      return temp;
    })
    .catch((err) => console.log(err));

  return out;
}
