import { Container, Box, Typography } from "@mui/material";
import { Facebook, YouTube, LinkedIn, Instagram } from "@mui/icons-material";
import style from "./style.module.css";

export function Footer() {
  return (
    <>
      <br />
      <br />
      <footer className={style.footer_sec}>
        <Container maxWidth="sm">
          <Box display="flex" justifyContent="space-between" sx={{ p: 4 }}>
            <a href="#">Register</a>
            <a href="#">Forum</a>
            <a href="#">Affiliate</a>
            <a href="#">FAQ</a>
          </Box>
          <Box display="flex" justifyContent="space-around" sx={{ p: 2 }}>
            <a href="#">
              <Facebook />
            </a>
            <a href="#">
              <YouTube />
            </a>
            <a href="#">
              <LinkedIn />
            </a>
            <a href="#">
              <Instagram />
            </a>
          </Box>
          <Typography sx={{ p: 4, color: "white" }}>
            <b>© 2022. Menu Boss. All rights reserved.</b>
          </Typography>
        </Container>
      </footer>
    </>
  );
}
