import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import qrcode from "qrcode";
import { ReadContext } from "../../context";
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import style from "./style.module.css";
import Header from "../header/Header";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";

const QrComponents = () => {
  const [qr, setQr] = useState("");
  const [url, setUrl] = useState("");
  const { userId } = ReadContext();
  const [userName, setUserName] = useState("");

  const GenerateQRCode = () => {
    setUrl(`https://foodmenu-website.pages.dev/view-listing/${userId}`);
    qrcode.toDataURL(
      url,
      {
        width: 260,
        margin: 2,
        color: {
          dark: "#000000",
          light: "#FFFFFF",
        },
      },
      (err, url) => {
        if (err) return console.error(err);

        setQr(url);
      }
    );
  };

   const checkAuth = () => {
     onAuthStateChanged(auth, (res) => {
       if (res) {
         setUserName(res.displayName);
         console.log("Response 49 Line : ", res.displayName);
       }
     });
   };

   useEffect(() => {
     checkAuth();
   }, []);

  useEffect(() => {
    GenerateQRCode();
  }, [url]);

  const printQrCode = () => {
    window.print();
  };
  return (
    <>
      <Header />
      <Container className={style.container_section}>
        <Grid
          container
          spacing={2}
          display="grid"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Typography
              variant="h2"
              sx={{ fontWeight: "500", margin: "20px 0 20px 0" }}
            >
              My QR Code
            </Typography>
            <Typography variant="h5" sx={{ margin: "30px 0 20px 0" }}>
              This is {userName} - Menu's QR Code
            </Typography>
            <img src={qr} alt={qr} className={style.imgSection} />
          </Grid>

          <Grid item>
            <a href={qr} download="qrcode.png">
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "200px", marginTop: "50px" }}
              >
                Download
              </Button>
            </a>
          </Grid>
          <Grid item>
            <a href={qr} download="qrcode.png">
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "200px", marginTop: "20px" }}
                onClick={printQrCode}
              >
                Print
              </Button>
            </a>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default QrComponents;
